import { IconBase } from "react-icons/lib";
import { Modal } from "reactstrap"



export const CustomModal = (props) => {
  const styles = {
    container: {
      width: '100%',
      height: '100%',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '24px',
      paddingRight: '8px',
      background: props.background, // Supondo que 'props' é um objeto disponível
      borderRadius: '4px',
      overflow: 'hidden',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '12px',
      display: 'inline-flex',

    },
    text: {
      flex: '1 1 0',
      color: '#171821',
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 600,
      wordWrap: 'break-word',
    },
    iconWrapper: {
      borderRadius: '4px',
      overflow: 'hidden',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      display: 'inline-flex',
    },
    iconButton: {
      alignSelf: 'stretch',
      paddingLeft: '18px',
      paddingRight: '18px',
      paddingTop: '9px',
      paddingBottom: '9px',
      borderRadius: '6px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      display: 'inline-flex',
    },
    icon: {
      width: '28px',
      height: '28px',
      backgraoudColor: '#171821'
    },
    check: {
      width: '12px',
      height: '8.25px',
      left: '3px',
      top: '4.5px',
      position: 'absolute',
      border: '1.5px solid #171821',
    },
    icontype:
      { height: '20px', width: '20px' }

  };
  return (
    <Modal
      style={{ width: '365px' }}
      backdrop={props.backdrop ?? "static"} isOpen={props.isOpen} fade={false} centered>
      <div style={styles.container}>
        <div style={styles.text}>{props.description}</div>
        <div style={styles.iconWrapper}>
          <div style={styles.iconButton}>
            <div style={styles.icon}>
              <IconBase
                style={styles.icontype}
              > {props.icon}</IconBase>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const CustomConfirmationModal = (props) => {
  const styles = styleCustomConfirmationModal
  return (
    <Modal backdrop="static" isOpen={true} fade={false} centered>
      <div style={styles.contentLayer1} />
      <div style={styles.contentLayer}>
        <div style={styles.desejaDesmarcarSeu}>{props.description}</div>
        <div style={styles.buttons}>
          <button style={styles.button}
            onClick={e => props.toogleModal({ confirm: false })}
          >
            <div style={styles.stateLayer}>
              <div style={styles.labelText}>Não</div>
            </div>
          </button>
          <button style={styles.button1}
            onClick={e => props.toogleModal({ confirm: true })}
          >
            <div style={styles.stateLayer}>
              <div style={styles.labelText1}>Sim</div>
            </div>
          </button>
        </div>
      </div>
    </Modal>
  );

}
const styleCustomConfirmationModal = {
  desejaDesmarcarSeu: {
    alignSelf: 'stretch',
    position: 'relative',
    fontWeight: 600
  },
  labelText: {
    position: 'relative',
    fontWeight: 600
  },
  labelText1: {
    position: 'relative',
    fontWeight: 600,
    color: '#FFF'
  },
  stateLayer: {
    alignSelf: 'stretch',
    borderRadius: '6px',
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 24px',
    boxSizing: 'border-box',
  },
  button: {
    flex: 1,
    borderRadius: '4px',
    backgroundColor: '#fafafa',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '45px',
    maxHeight: '45px',
    borderColor: 'transparent',

  },
  button1: {
    flex: 1,
    borderRadius: '4px',
    backgroundColor: '#171821',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '45px',
    maxHeight: '45px',
    borderColor: 'transparent',

  },
  buttons: {
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px'
  },
  contentLayer: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#fff',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 16px 16px',
    boxSizing: 'border-box',
    gap: '32px',
    textAlign: 'center',
    fontSize: '16px',
    color: '#171821',
    fontFamily: 'Inter, sans-serif'
  },
  contentLayer1: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#171821',
    height: '56px',
    overflow: 'hidden',
    flexShrink: 0
  }
};
export const PaymentAlternativeModal = (props) => {
  const styles = styleCustomConfirmationModal;
  return (
    <Modal backdrop="static" isOpen={true} fade={false} centered>
      <div style={styles.contentLayer1} />
      <div style={styles.contentLayer}>
        <div style={styles.desejaDesmarcarSeu}>{"Por favor, selecione o tipo de pagamento para concluir seu agendamento."}</div>
        <div style={styles.buttons}>
          <button style={styles.button1}
            onClick={e => props.toogleModal({ confirm: false })}
          >
            <div style={styles.stateLayer}>
              <div style={styles.labelText1}>Total</div>
            </div>
          </button>
          <button style={styles.button1}
            onClick={e => props.toogleModal({ confirm: true })}
          >
            <div style={styles.stateLayer}>
              <div style={styles.labelText1}>Parcial</div>
            </div>
          </button>
        </div>
      </div>
    </Modal>
  );

}
// .deseja-desmarcar-seu {
//   align-self: stretch;
//   position: relative;
//   font-weight: 600;
// }
// .buttons,
// .content-layer {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-start;
//   gap: 12px;
// }
// .content-layer {
//   width: 365px;
//   background-color: var(--base-base-white);
//   max-width: 100%;
//   overflow: hidden;
//   flex-direction: column;
//   justify-content: center;
//   padding: 32px var(--padding-base) var(--padding-base);
//   box-sizing: border-box;
//   gap: 32px;
//   line-height: normal;
//   letter-spacing: normal;
//   text-align: center;
//   font-size: var(--text-h2-size);
//   color: var(--color-primary-500);
//   font-family: var(--text-h2);
// }


