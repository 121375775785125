export const enumStatusScheduling={
    Agendado : 0,
    Atendido :2,
    Particular :5,
    Chegou :6,
    Confirmado :7,
    Cancelado :1,
    Faltou :3,
    Desmarcou :4,
}
