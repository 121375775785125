import React, { Component } from "react";
import {
  Row,
  Col,
  CardBody,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { useNavigate } from "react-router-dom";
import { ptBR } from "date-fns/locale";
import { FaChevronRight, FaSpinner } from "react-icons/fa";
import moment from "moment";
import { FaChevronLeft } from "react-icons/fa";
import { addDays, subDays, parseISO, format } from "date-fns";

export default class Professionals extends Component {
  constructor(props) {
    super(props);
    const myParam = window.location.pathname.split("/")[1];
    this.state = {
      listProvider: [],

      myParam: myParam,
      formDay: [],
      listDaysFunctionality: [],
      listScheduling: [],
      dateSelected: new Date(),
      loading: false,
      professionalLoading: true,
    };
  }

  componentDidMount = async () => {
    if (localStorage.getItem("listProcedureId") == null) {
      this.toggle();
    } else {
      await this.consultProviders();
      await this.searchAppointmentList();
    }
  };

  consultProviders = async () => {
    const { myParam } = this.state;
    if (myParam != "") {
      await axios
        .get(`${URL_PreScheduling}/GetProviders`, {
          params: {
            aliasClinic: myParam,
            listIdProcedure: localStorage.getItem("listProcedureId"),
          },
        })
        .then((response) => {
          const { data } = response;
          if (data != "Clínica não localizada!") this.arrayProviders = data;
          this.setState({
            listProvider: data,
          });
        })
        .catch(() => {
          this.setState({ professionalLoading: false });
        });
      this.setState({ professionalLoading: false });
    }
  };

  searchAppointmentList = async (date) => {
    let listIdCompanyProvider = [];
    this.arrayProviders.forEach((element) =>
      listIdCompanyProvider.push(element.idCompanyProvider)
    );
    let listProcedures = JSON.parse(localStorage.getItem("listProcedures"));
    let servicesDuration = 0;
    if (listProcedures != null && listProcedures.length > 0) {
      listProcedures.forEach((element) => {
        servicesDuration += element.duration;
      });
    }
    const { myParam } = this.state;
    let listScheduling = [];
    await axios
      .get(
        `${URL_PreScheduling}/GetSchedulingDay/${myParam}/${servicesDuration}`,
        {
          params: {
            listProvidersKey: JSON.stringify(listIdCompanyProvider),
            date: date == undefined ? new Date() : date,
          },
        }
      )
      .then((resp) => {
        const { data } = resp;
        if (data.length > 0) {
          if (data) {
            data.forEach((element) => {
              let map = {
                hourList: element.hourList,
                idCompanyProvider: element.idCompanyProvider,
                day: date == undefined ? new Date() : date,
              };
              listScheduling.push(map);
            });

            for (let schedule of listScheduling)
              schedule.hourList.sort((a, b) => moment(a).diff(moment(b)));

            this.setState({ formDay: listScheduling });
          }
        }
      });
  };

  searchAppointmentProvider = async (date, id) => {
    let listIdCompanyProvider = [];
    listIdCompanyProvider.push(id);
    let listProcedures = JSON.parse(localStorage.getItem("listProcedures"));
    let servicesDuration = 0;
    if (listProcedures != null && listProcedures.length > 0) {
      listProcedures.forEach((element) => {
        servicesDuration += element.duration;
      });
    }
    const { myParam, formDay } = this.state;
    await axios
      .get(
        `${URL_PreScheduling}/GetSchedulingDay/${myParam}/${servicesDuration}`,
        {
          params: {
            listProvidersKey: JSON.stringify(listIdCompanyProvider),
            date: date == undefined ? new Date() : date,
          },
        }
      )
      .then((resp) => {
        const { data } = resp;
        if (data.length > 0) {
          if (data) {
            formDay.forEach((e) => {
              if (e.idCompanyProvider == data[0].idCompanyProvider) {
                e.hourList = data[0].hourList;
                e.idCompanyProvider = data[0].idCompanyProvider;
                e.day = date == undefined ? new Date() : date;
              }
            });
            for (let schedule of formDay)
              schedule.hourList.sort((a, b) => moment(a).diff(moment(b)));

            this.setState({ formDay });
          }
        }
      });
  };

  validate = () => {
    const { model } = this.state;
    const errors = {};
    let isError = 0;
    if (!model.idDoctor) {
      isError++;
      errors.idDoctorError = true;
    } else errors.idDoctorError = false;
    this.setState({
      errors: errors,
    });
    return isError;
  };

  setValues = async (e, field) => {
    const { model } = this.state;
    if (field == "idDoctor") {
      model[field] = e;
    } else model[field] = e.target.value;
    this.setState({ model });
    this.validate();
  };

  onSelect = async (d, idCompanyProvider) => {
    this.setState({ loading: true, date: d });
    await this.searchAppointmentProvider(d, idCompanyProvider);
    this.setState({ loading: false });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const {
      listProvider,
      myParam,
      loading,
      professionalLoading,
      formDay,
      date,
      idCompanyProviderSelected,
    } = this.state;

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",

            alignSelf: 'stretch',
            marginBottom: '24px'
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "40px",
              backgroundColor: "transparent", // Ghost button, no background
              border: "1px", // Dark Blue color for border
              color: "#003366", // Dark Blue color for icon
              borderRadius: "4px 0 0 0",
              opacity: 1, // Assuming opacity 0px in the design is a typo
              cursor: "pointer",
              radius: "6px",
              paddingTop: "9px",
              paddingRight: "16px",
              paddingBottom: "9px",
            }}
            onClick={() => window.history.back()} // Replace with your actual event handler
          >
            <FaChevronLeft size={12} />
          </button>
          <div
            className="text-left"
            style={{
              fontFamily: 'inter,sans-serif',
              color: '#171821',
              flex: '1 0 0',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Selecione a data e horário desejado
          </div>
        </div>
        {this.state.modal == false || this.state.modal == undefined ? (
          <div>
            {listProvider.length > 0 ? (
              <div>
                <Cards
                  myParam={myParam}
                  listProvider={listProvider}
                  idCompanyProviderSelected={idCompanyProviderSelected}
                  loading={loading}
                  formDay={formDay}
                  onSelect={this.onSelect}
                  date={date}
                />
              </div>
            ) : (
              <div>
                {professionalLoading && (
                  <FaSpinner className="icon_pulse text-center" />
                )}
                {!professionalLoading && (
                  <h6 className="text-center mt-4"
                    style={{ fontFamily: 'inter,sans-serif', color: 'red' }}
                  >
                    Nenhum profissional presta todos serviços selecionados!
                  </h6>
                )}
              </div>
            )}{" "}
          </div>
        ) : (
          <Modal
            centered={true}
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            backdrop="static"
          >
            <ModalHeader>Selecione um serviço!</ModalHeader>
            <ModalBody>
              É obrigatório selecionar um serviço, você será direcionado para a
              tela de serviços.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" href={`/${myParam}`}>
                OK
              </Button>{" "}
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

function Cards(props) {
  const navigate = useNavigate();
  const redirectScheduling = (h, l, p) => {
    localStorage.setItem("providerSelected", JSON.stringify({ 'nameProvider': p.nameCompanyProvider, 'idCompanyProvider': l.idCompanyProvider }));
    localStorage.setItem("dateTimeSelected", h);
    navigate("/" + props.myParam + "/scheduling", { replace: false });
  };

  const selectedDate = (data, id) => {
    props.onSelect(data, id);
  };

  const handlePrevDay = (l, index) => {
    const prevDate = subDays(l.day, 1);
    updateDayForProvider(prevDate, index);
    selectedDate(l.day, l.idCompanyProvider);
  };

  const handleNextDay = (l, index) => {
    const nextDate = addDays(l.day, 1);
    updateDayForProvider(nextDate, index);
    selectedDate(l.day, l.idCompanyProvider);
  };

  const updateDayForProvider = (newDate, index) => {
    try {
      const updatedFormDay = [...props.formDay];
      updatedFormDay[index].day = newDate;

    } catch (error) {
      console.error("Error in updateDayForProvider:", error);
    }
  };
  const limitDays = localStorage.getItem("LimitPreScheduling");

  const newDateLimit = addDays(new Date(), limitDays);
  return (
    <div
      style={{
        gap: "24px",
        display: "flex",
        maxWidth: "1320px",
        justifyContent: "center",
        alignItems: "stretch",
        alignContent: "flex-start",
        alignSelf: "stretch",
        flexWrap: "wrap",
      }}
    >

      {props.listProvider.map((p, index) => (
        <div
          key={p.id}
          style={{
            display: "flex",
            minWidth: "340px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            flex: "1 0 0",
            borderRadius: "4px",
            border: "1px solid var(--Color-Gray-100, #E3E3E3",
          }}
        >
          <CardBody>
            <div
              style={{
                height: "100",
                width: "100%",
                margin: "0px",
                padding: "0px",
              }}
            >
              <div style={{ width: "100%", height: "108px", alignItems: 'center', textAlign: 'center' }}>
                {p.logo != null ? (
                  <img
                    className="mb-2 img-fluid rounded-circle thumb64"
                    width={100}
                    height={108}
                    src={"data:image/png;base64," + p.logo}
                    alt="Contact"
                    style={{
                      width: "100px",
                      height: "108px",
                      objectFit: "cover", // Ensures the image covers the area without distortion
                      objectPosition: "center", // Centers the image within the box
                      paddingTop: "8px",
                    }}
                  />
                ) : (
                  <img
                    width={100}
                    height={108}
                    className="mb-2 img-fluid rounded-circle thumb64"
                    src="/Image/sem_foto.png"
                    alt="Contact"
                    style={{ paddingTop: "8px" }}
                  />
                )}
              </div>
              <div
                className="media-body pt-1"
                style={{
                  width: "100%",
                  height: "50px",
                  radius: "7.35px",
                  textAlign: "center",
                  margin: "0px",
                }}
              >
                <h6
                  style={{
                    fontFamily: "Inter, sans-serif", // Font family
                    fontSize: "16px", // Font size
                    fontWeight: 600, // Font weight
                    lineHeight: "19.36px", // Line height
                    textAlign: "center", // Centers text within the h6 itself
                    padding: "0px",
                    margin: "0px",
                    borderBottom: "1px solid var(--Color-Gray-100, #E3E3E3)",
                    paddingTop: "16px",
                    paddingRight: "8px",
                    paddingLeft: "8px",
                    paddingBottom: "16px",
                    gap: "8px",
                    height: "50px",
                    width: "100%",
                  }}
                >
                  {p.nameCompanyProvider}
                </h6>
              </div>
              <div>
                {props.loading == true ? (
                  <div className="text-center">
                    <FaSpinner className="icon_pulse" />
                  </div>
                ) : (
                  <div>
                    {/* days={limitDays > 0 ? limitDays : null} */}
                    {props.formDay.length > 0
                      ? props.formDay.map((l, index) =>
                        l.idCompanyProvider == p.idCompanyProvider ? (
                          <div key={index}>
                            <div
                              style={{
                                display: "flex", // Flexbox for horizontal alignment
                                alignItems: "center", // Vertically center the children
                                width: "100%",
                                height: "50px",
                                textAlign: "center",
                                margin: "0px",
                                borderBottom: "1px solid var(--Color-Gray-100, #E3E3E3)",
                              }}
                            >
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: "transparent", // Ghost button, no background
                                  border: "1px",
                                  color: "#171821",
                                  borderRadius: "4px",
                                  opacity: 1, // Assuming opacity 0px in the design is a typo
                                  cursor: "pointer",
                                  radius: "6px",
                                  gap: "8px",
                                  paddingTop: "9px",
                                  paddingRight: "16px",
                                  paddingBottom: "9px",
                                  paddingLeft: "16px",
                                  marginTop: "5px",
                                }}
                                onClick={() => handlePrevDay(l, index)}
                              >
                                <FaChevronLeft size={12} />
                              </button>
                              <div
                                key={p.id}
                                className="text-center"
                                style={{
                                  width: "100%",
                                  height: "50px",

                                  justifyContent: "space-between",
                                  paddingTop: "14px",
                                  paddingRight: "6px",
                                  paddingBottom: "5px",
                                  paddingLeft: "6px",
                                }}
                              >
                                <div
                                  style={{
                                    overflow: 'hidden',
                                    color: 'var(--Color-Primary-500, #171821)',
                                    textAlign: 'center',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Inter, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                    padding: '5px 6px'
                                  }}
                                > {format(l.day, "PPPP", { locale: ptBR })}</div>
                              </div>
                              <button
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: "transparent", // Ghost button, no background
                                  border: "1px", // Dark Blue color for border
                                  color: "#171821", // Dark Blue color for icon
                                  borderRadius: "4px",
                                  opacity: 1, // Assuming opacity 0px in the design is a typo
                                  cursor: "pointer",
                                  radius: "6px",
                                  gap: "8px",
                                  paddingTop: "9px",
                                  paddingRight: "16px",
                                  paddingBottom: "9px",
                                  paddingLeft: "16px",
                                  marginTop: "5px",
                                }}
                                onClick={() => handleNextDay(l, index)}
                              >
                                <FaChevronRight size={12} />
                              </button>
                            </div>
                            <Row
                              style={{
                                width: "96%",
                                height: "100%",
                                paddingTop: "12px",
                                paddingRight: "8px",
                                paddingBottom: "12px",
                                gap: "10px",
                                margin: "0px",
                              }}
                            >
                              {l.hourList.length > 0 &&
                                new Date() <= parseISO(l.hourList.slice(-1)[0]) &&
                                parseISO(l.hourList.slice(-1)[0]) <= newDateLimit ? (
                                l.hourList.map((h, index) =>
                                  parseISO(h) > new Date() ? (
                                    <Col
                                      key={index}
                                      xs={2}
                                    >
                                      <Badge
                                        color="dark"
                                        style={{
                                          fontFamily: "Inter, sans-serif",
                                          backgroundColor: "#171821", // Ensure the Badge inherits the same background
                                          border: "none",
                                          padding: '11px 12px',
                                          borderRadius: '6px',
                                          gap: '8px',
                                          minHeight: '35px',
                                          maxHeight: '35px'// Remove any border around the Badge
                                        }}
                                      >
                                        <div
                                          style={{
                                            color: 'var(--Color-White-Pure, #FFF)',

                                            fontFamily: 'Inter, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: 600,
                                            lineHeight: 'normal',
                                            padding: '8,8,0,0'
                                          }}
                                          onClick={(e) =>
                                            redirectScheduling(h, l, p)
                                          }
                                        >
                                          {format(
                                            parseISO(h, new Date()),
                                            "HH:mm"
                                          )}
                                        </div>
                                      </Badge>
                                      {" "}
                                    </Col>
                                  ) : null
                                )
                              ) : (
                                <div className="text-center mt-5">
                                  <h6 className="text-danger"
                                  style={{fontFamily:'inter,sans-serif'}}
                                  >
                                    Profissional sem horário disponível
                                    nessa data!
                                  </h6>
                                </div>
                              )}
                            </Row>
                          </div>
                        ) : null
                      )
                      : null}
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </div>
      ))}
    </div>
  );
}
