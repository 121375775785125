
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import { Component } from 'react';
import './App.css'
export default class App extends Component {

  render() {
    return (
      <BrowserRouter >
        <Router />
      </BrowserRouter>
    );
  }
}
