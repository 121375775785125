import React, { Component, useState, useEffect, useRef } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Loader } from '@googlemaps/js-api-loader';
import { FaChevronLeft } from "react-icons/fa";
import { TbCopy } from "react-icons/tb";
export default class Location extends Component {
  state = {
    modelClinic: {},
    load: true
  };
  componentDidMount = async () => {
    let data = await JSON.parse(localStorage.getItem("dataClinic"));
    this.setState({ modelClinic: data, load: false });
  }

  toggleClick = () => {
    const { modelClinic } = this.state;

    const end = `${modelClinic?.address}, ${modelClinic?.number ?? ""
      } - ${modelClinic?.bairro}`
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(end);
    }
  };

  render() {
    const { modelClinic, load } = this.state;
    return (
      <div
        style={{
          width: "100%",
          paddingTop: "8px",
          paddingRight: "8px",
          paddingBottom: "32px",
          paddingLeft: "8px",
          flexDirection: "column",
          alignItems: "center",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px",
            alignSelf: "stretch",
          }}
        >
          <button
            style={{
              display: "flex",
              minHeight: "40px",
              maxHeight: "40px",
              width: "40px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              border: "1px",
              paddingTop: "9px",
              paddingRight: "16px",
              paddingBottom: "9px",
              paddingLeft: "16px",
              backgroundColor: "transparent",
            }}
            onClick={() => window.history.back()}
          >
            <FaChevronLeft style={{ width: "14px", height: "14px" }} />
          </button>

          <div
            style={{
              color: "var(--Color-Primary-500, #171821)",
              fontFamily: "inter, sans-serif",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "normal",
            }}
          >
            Localização
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            paddingTop: "24px",
            paddingBottom: "24px",
            alignSelf: "self",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "60px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
              alignSelf: "stretch",
            }}
          >
            <label
              style={{
                display: "flex",
                height: "20px",
                paddingTop: "2px",
                paddingBottom: "1px",
                alignItems: "center",
                flexShrink: "0",
                alignSelf: "stretch",
                fontWeight: "500",
                fontFamily: "inter, sans-serif",
                fontSize: "14px"
              }}
            >
              Endereço
            </label>
            <div
              style={{
                display: "flex",
                position: "relative",
                width: "100%", // Ensure the container takes full width
              }}
            >
              <input
                disabled={true}
                type="text"
                className="form-control"
                value={`${modelClinic?.address}, ${modelClinic?.number ?? ""
                  } - ${modelClinic?.bairro}`}
                style={{
                  width: "100%", // Full width for the input
                  minHeight: "36px",
                  maxHeight: "36px",
                  paddingTop: "6px",
                  paddingRight: "40px", // Adjust padding to make room for the icon
                  paddingBottom: "6px",
                  paddingLeft: "8px",
                  borderRadius: "4px",
                  background: "#FAFAFA",
                  fontSize: "14px"
                }}
              />
              <TbCopy
                onClick={() => this.toggleClick()}
                style={{
                  position: "absolute",
                  right: "10px", // Position it inside the textbox
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  height: "24px",
                  width: "24px",
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              height: "60px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
              alignSelf: "stretch",
            }}
          >
            <label
              style={{
                display: "flex",
                height: "20px",
                paddingTop: "2px",
                paddingBottom: "1px",
                alignItems: "center",
                flexShrink: "0",
                alignSelf: "stretch",
                fontWeight: "500",
                fontFamily: "inter, sans-serif",
                fontSize: "14px"
              }}
            >
              Telefone
            </label>
            <input
              disabled={true}
              value={
                !modelClinic.cellPhone
                  ? ""
                  : formatPhoneNumber(modelClinic.cellPhone)
              }
              className="form-control"
              type="text"
              style={{
                display: "flex",
                minHeight: "36px",
                maxHeight: "36px",
                paddingTop: "6px",
                paddingRight: "8px",
                paddingBottom: "6px",
                paddingLeft: "8px",
                gap: "8px",
                alignSelf: "stretch",
                borderRadius: "4px",
                background: "#FAFAFA",
                fontSize: "14px"
              }}
            />
          </div>
        </div>

        <div
          className="row"
          style={{ height: "350px", alignSelf: "stretch", borderRadius: "8px" }}
        >
          <div className="col">
            {!load &&
              <MapContainer
                address={`${modelClinic?.address}${modelClinic?.number ?? ""} - ${modelClinic?.bairro
                  }`}
              />}
          </div>
        </div>
      </div>
    );
  }
}
function formatPhoneNumber(phoneNumber) {
  // Remove qualquer caractere que não seja um número
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Adiciona a máscara (XX) XXXXX-XXXX
    if (phoneNumber.length > 10) {
      phoneNumber = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else {
      // Caso seja um número com 10 dígitos (sem o nono dígito)
      phoneNumber = phoneNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }

    return phoneNumber;
  }
}

function MapContainer(props) {
  const mapRef = useRef(null);
  const [address] = useState(props.address);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyDY33IZvP-Iop6ck82jSrUrO67ZY0k5IWo",
      version: 'weekly',
      libraries: ['places'],
    });

    // Nova forma de carregar a API com conformidade
    (async () => {
      try {

        const google = await loader.load(); // Uso assíncrono para obter o objeto google

        const geocoder = new google.maps.Geocoder();


        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK') {
            const map = new google.maps.Map(mapRef.current, {
              center: results[0].geometry.location,
              zoom: 14,
            });

            new google.maps.Marker({
              map: map,
              position: results[0].geometry.location,
            });
          } else {
            console.error('Geocoding failed: ' + status);
          }
        });
      } catch (e) {
        console.error('Erro ao carregar o Google Maps: ', e);
      }
    })();
  }, [address]);

  return <div ref={mapRef} style={{ height: '400px', width: '100%' }} />;
};
